.form-3ArrZ {
	padding-top: 16px;

	background: var(--white);
}

.form__wrapper-3qAZ1,
.inner__summary-2Dqzh {
	max-width: var(--l-container);
}

.route-2x2_I,
.passengers-29QjR,
.passengers-29QjR:before {
	font-size: 21px;
}

.route-2x2_I {
	font-weight: 500;
}

.passengers-29QjR {
	position: relative;

	padding-left: 0;

	border-left: none;
}

.passengers-29QjR:before {
	position: absolute;
	top: 1px;
	left: -13px;

	display: inline-block;
	content: ',';
}

div.quickSearchForm__promoCode-2p3Lp {
	color: var(--white);
}

@media screen and (max-width: 600px) {
	.route-2x2_I,
	.passengers-29QjR {
		font-size: 18px;
		font-weight: 600;
	}

	.passengers-29QjR:before {
		display: none;
	}

	.outer-1jUGd {
		/* z-index: 3; */
	}
}
