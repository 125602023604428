

.wrapper-3Eb2L {
	justify-content: space-between;
	height: 60px;

	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background: var(--white);
}

button.button_back-3euZf {
	color: #9a9a9a;
}

button.button_continue-VymhT,
button.button_continue-VymhT:hover {
	min-width: 172px;
	height: auto;
	padding: 8px 10px;

	border-radius: 0;

	font-weight: 600;

	background: var(--scat-green);
}
