.checkout__checkoutNarrow-21icx,
.paymentForm__wrapper-3Rt8X {
	max-width: 1280px;
}

.iframe-bvb1U {
	height: 1000px;
	width: 100%;
}

.cards_header-35DWX {
	display: none;
}

@media (max-width: 1024px) {
	.iframe-bvb1U {
		height: 1130px;
	}
}
