

.stepbar-30GMX {
	background-color: var(--scat-blue);
}

.inner-1UMvf {
	max-width: var(--l-container);

	background-color: var(--scat-blue);
}
