

div.day_highlight-DwA0r {
	border-radius: 0;
}

div.day__day-BGV9o .day_inside-2J_p_ {
	width: 48px;
	height: 48px;

	border-radius: 0;

	background: transparent;
}

div.day__day-BGV9o.day__endPeriod-2XaOl:before,
div.day__day-BGV9o.day__startPeriod-1BVZv:after {
	display: none;
}

div.day__day-BGV9o.day_selected-3Wnk8 {
	padding-top: 0;
}

div.day_selected-3Wnk8 .day_inside-2J_p_ {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 0;

	border-radius: 8px;

	text-align: center;

	background: transparent;
}

div.day__day-BGV9o.day_selected-3Wnk8.day__startPeriod-1BVZv .day_inside-2J_p_ {
	border-radius: 8px 0 0 8px;
}

div.day__day-BGV9o.day_selected-3Wnk8.day__endPeriod-2XaOl .day_inside-2J_p_ {
	border-radius: 0 8px 8px 0;
}

div.datepicker__modal-3hdSi {
	padding: 20px;
}

.requestForm_terms-12tj4 {
	color: #5698f0;
}

div.exchange_prices-2tqyY > div {
	display: none;
}

label.choice__label-_bVSv.Unvoluntary-QiOv_ {
	display: none;
}

.timelimit-1e5A- {
	display: none;
}
