

.seatMap_disclaimer-2iKDy p {
	white-space: break-spaces;
}

.rowPartWrp-3zoJW {
	justify-content: flex-start;
}

button.seat-1KmaV {
	max-width: 41px;
}

.seatTooltip__seatName-1iMhr {
	color: var(--gray);
}

.free-2vRmn .number-1TZrY {
	color: var(--gray);
}

.airplane_return-jEYLr .airplane__image-GDBtc {
	transform: none;
}

/* .unexistent between :not(.unexistent) */
.seat-1KmaV:not(.unexistent-1YXYn):has(+ .unexistent-1YXYn + .seat-1KmaV:not(.unexistent-1YXYn)) + .unexistent-1YXYn {
	display: none;
}

.rowPartWrpWithTechFacilities-1PchW:last-child .rowPartWrp-3zoJW {
	justify-content: flex-end;
}
