.container-3NoQL {
	position: relative;

	flex: 1;
}

.label-3C808 {
	position: absolute;
	top: 0;
	left: 14px;

	color: var(--text-light);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;

	text-transform: lowercase;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.label-3C808 {
		left: 0;
	}
}
