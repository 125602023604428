

.currentSorting-3yjzF {
	color: #828282;
}

button.mobile__list__button-3gYRc,
button.mobile__list__button-3gYRc:hover {
	color: #4f4f4f;
	font-weight: 500;
	font-size: 16px;

	background: rgba(93, 194, 239, 0.2);
}

.switch__label-31OIK {
	color: #4f4f4f;
	font-weight: 500;
	font-size: 16px;
}

button.mobile__list__button_inactive-3-VHs {
	background: var(--white);
}
