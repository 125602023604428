

label.textField__root-2XueY.textField-2MHnn:not(.label_error-2opVC) {
	color: var(--scat-blue);
}

div.input-26qiI:after {
	border-bottom-color: var(--scat-blue);
}

div.input_autocomplete-35ifP:hover:before,
div.input_phone-1J5-3:hover:before,
div.input-26qiI:hover:before {
	border-bottom-color: var(--scat-blue) !important;
}

div.input_phone-1J5-3:after {
	border-bottom-color: var(--scat-blue);
}

label.autocomplete__root-TEteY.focused-1iPYS:not(.label_error-2opVC) {
	color: var(--scat-blue);
}

div.input_select-1jjbP.select_focused-2YOAA {
	color: var(--scat-blue);
}
