.insurance-3w3Pl {
	margin-bottom: 20px;
	padding: 20px;

	border-radius: 0;
}

.insurance-3w3Pl.selected-5qFV5 {
	box-shadow: 0 0 2px 2px var(--scat-blue);
}

.insurance__header-1d9Xv {
	padding: 0 0 20px;

	border-bottom: 1px dashed #cccccc;
}

.insurance__content-1hZBE {
	padding: 20px 0 0;
}

.insurance__money-1_LTb {
	position: relative;

	color: var(--scat-blue);
}

.selected-5qFV5 .insurance__money-1_LTb {
	padding-right: 30px;
}

.selected-5qFV5 .insurance__money-1_LTb:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;

	display: block;
	width: 24px;
	height: 24px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDEyQzIgNi40OCA2LjQ4IDIgMTIgMkMxNy41MiAyIDIyIDYuNDggMjIgMTJDMjIgMTcuNTIgMTcuNTIgMjIgMTIgMjJDNi40OCAyMiAyIDE3LjUyIDIgMTJaTTUgMTJMMTAgMTdMMTkgOEwxNy41OSA2LjU4TDEwIDE0LjE3TDYuNDEgMTAuNTlMNSAxMloiIGZpbGw9IiMxNkM1NjAiLz4KPC9zdmc+Cg==);
	content: '';
}

button.insurance__button-3l8fq {
	border-color: var(--scat-blue);
	border-radius: 0;

	font-size: 17px;

	color: var(--scat-blue);
}

button.insurance__button-3l8fq:hover {
	color: #ffffff;

	background: var(--scat-blue);
}

button.insurance__button-3l8fq.insurance__button_selected-1BOBj {
	border-color: #f2f2f2;

	color: var(--scat-darken-gray);
	text-align: center;

	background: #f2f2f2;
}

button.insurance__button-3l8fq.insurance__button_selected-1BOBj:hover {
	background: #e0e0e0;
}

button.insurance__button-3l8fq.insurance__button_selected-1BOBj:after {
	display: none;
}

@media screen and (max-width: 600px) {
	button.insurance__button-3l8fq {
		padding-right: 12px;

		border: 1px solid var(--scat-blue);
		border-radius: 0;

		color: var(--scat-blue);

		background: #ffffff;
	}

	button.insurance__button-3l8fq:after {
		display: none;
	}

	button.insurance__button-3l8fq:hover {
		color: #ffffff;

		background: var(--scat-blue);
	}

	button.insurance__button-3l8fq:hover .insurance__money-1_LTb {
		color: #ffffff;
	}

	button.insurance__button-3l8fq.insurance__button_selected-1BOBj {
		border-color: #f2f2f2 !important;

		color: var(--scat-darken-gray);
		text-align: center;

		background: #f2f2f2 !important;
	}

	button.insurance__button-3l8fq.insurance__button_selected-1BOBj:hover .insurance__money-1_LTb {
		color: var(--scat-blue);
	}
}
