.container-292aB {
	display: flex;
	flex-direction: column;
}

.wrapper-3fn9X {
	position: relative;

	width: 100%;
	max-width: var(--l-container);

	margin: 0 auto;
}

.linkWithIcon-zaZ0j {
	display: flex;
	align-items: center;
	grid-gap: 9px;
	gap: 9px;
	justify-content: space-between;
}

.linkWithIcon-zaZ0j:before {
	content: ' ';

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDJMMTEuMDU5IDIuMDAxNzNMMTEuMTE2NiAyLjAwNjczTDExLjE3NjQgMi4wMTU2TDExLjIyOTMgMi4wMjY0MUwxMS4zMzczIDIuMDU4M0wxMS40MDM5IDIuMDg0OTJMMTEuNTM2MSAyLjE1NTY5TDExLjYyNTQgMi4yMTk2OUwxMS43MDcxIDIuMjkyODlMMjAuNzQ1NCAxMS4zMzMzQzIxLjU1MzcgMTIuMjM3MSAyMS41OTIyIDEzLjU4MjEgMjAuODI3OCAxNC41NjI4TDIwLjcwNzEgMTQuNzA3MUwxNC42NjY3IDIwLjc0NTRDMTMuNzYyOSAyMS41NTM3IDEyLjQxNzkgMjEuNTkyMiAxMS40MzcyIDIwLjgyNzhMMTEuMjkyOSAyMC43MDcxTDIuMjkyODkgMTEuNzA3MUMyLjEzNjYxIDExLjU1MDggMi4wMzc0IDExLjM0ODEgMi4wMDg2NyAxMS4xMzE0TDIgMTFWN0MyIDQuMjM4NTggNC4yMzg1OCAyIDcgMlpNMTAuNTg1IDRIN0M1LjQwMjMyIDQgNC4wOTYzNCA1LjI0ODkyIDQuMDA1MDkgNi44MjM3M0w0IDdWMTAuNTg1TDEyLjY2NjcgMTkuMjU0NkMxMi44MzI3IDE5LjQwMzIgMTMuMDczMyAxOS40MjE4IDEzLjIzMDkgMTkuMzM2TDEzLjI5MjkgMTkuMjkyOUwxOS4yNTQ2IDEzLjMzMzNDMTkuNDAzMiAxMy4xNjczIDE5LjQyMTggMTIuOTI2NyAxOS4zMzYgMTIuNzY5MUwxOS4yOTI5IDEyLjcwNzFMMTAuNTg1IDRaTTkgNkM3LjM0MzE1IDYgNiA3LjM0MzE1IDYgOUM2IDEwLjY1NjkgNy4zNDMxNSAxMiA5IDEyQzEwLjY1NjkgMTIgMTIgMTAuNjU2OSAxMiA5QzEyIDcuMzQzMTUgMTAuNjU2OSA2IDkgNlpNOSA4QzkuNTUyMjggOCAxMCA4LjQ0NzcyIDEwIDlDMTAgOS41NTIyOCA5LjU1MjI4IDEwIDkgMTBDOC40NDc3MiAxMCA4IDkuNTUyMjggOCA5QzggOC40NDc3MiA4LjQ0NzcyIDggOSA4WiIgZmlsbD0iIzFBNTdCQiIvPgo8L3N2Zz4K) no-repeat;
}

@media screen and (max-width: 600px) {
	.promoCode-228xI {
		width: 100%;
	}
}
