
/* stylelint-disable */
div.scrollBody-1KjEp {
}
/* stylelint-enable */
div.modal-1-H6U {
	border-radius: 20px;
}

.closeIcon-te75K {
	top: 28px;
	right: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-pale);

	background: #f6f6f6;
}

.closeIcon-te75K:hover {
	color: var(--text-light);

	background: #edebeb;
}

.closeIcon-te75K svg {
	width: 20px;
	height: 20px;
}

.warningModal_header-3HRK0 {
	border-radius: 20px;
}
