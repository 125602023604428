

.wrapper-11QBb {
	max-width: var(--l-container);
}

.flights-fxCzn {
	max-width: var(--l-container);
	margin-top: -7px;
}

.planeIcon-1UtiL {
	transform: rotate(180deg) translateY(3px);
}

div.flight-KXGeE:last-child:not(.flight_noMargin-2FM0z) {
	margin-bottom: 0;
}
