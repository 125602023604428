

.content-1pZma {
	max-width: var(--l-container);
}

.banner_meal-1muFL,
.banner_seat-ZfzLW,
.banner_baggage-1dONw {
	display: none;
}

.banner_baggage-1dONw .image-1QRxB {
	right: 37px;
}

.banner_seat-ZfzLW {
	display: none;
}
