button.selector-1ScYO {
	width: 125px;
	height: 60px;
	padding-top: 26px;

	font-weight: 700;
	font-size: 22px;
}

.currency-3QxRi button.selector-1ScYO,
.currency-3QxRi button.selector-1ScYO:hover {
	color: var(--brand-1-0);

	background-color: transparent !important;
}

.item-U8TyB {
	color: var(--dark-gray);
}

.dropdown-3Jb6R {
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 600px) {
	.currency-3QxRi {
		margin: 0;
	}

	.arrowIcon-1ojKG {
		display: none;
	}

	button.selector-1ScYO {
		padding-left: 0;
		justify-content: flex-start;
		padding-top: 35px;

		font-size: 18px;
		font-weight: 700;
	}

	.dropdown-3Jb6R {
		box-shadow: none;
	}
}
