.controls-3txk3 {
	flex-basis: 29%;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.controls-3txk3 {
		top: auto;
		right: 0;
		bottom: 0;

		padding-right: 0;
	}
}

@media screen and (max-width: 600px) {
	.controls-3txk3 {
		margin-top: 6px;
	}
}
