.fareGroups-EWL58 {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
}

.fareGroup-3bl-l {
	width: auto;
	max-width: 100%;
	padding: 16px 13px 13px 13px;

	border-radius: 10px;

	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.04);
}

.fareGroup-3bl-l:nth-child(2):not(:last-of-type) {
	margin: 0;
}

.fareGroup-3bl-l:nth-child(2):last-of-type {
	margin: 0;
}

.options-1dCj5 {
	padding-top: 0;
}

.keyFeature-2oVPM {
	width: auto;
	height: auto;

	background-color: var(--scat-green);
}

.keyFeatures-1SLtp {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 2px;
}

.fareGroup__title-1HRHO {
	line-height: 31px;
}

.infoIcon-1k8k8 {
	margin-left: 2px;
	margin-top: -2px;
}

.infoIcon-1k8k8 > svg {
	width: 16px;
	height: 16px;
}

.value-2_cSJ {
	font-size: 15px;
	font-weight: 600;
}

.keyFeature-2oVPM:not(:nth-child(4n)) {
	margin-right: 0;
}

.fareGroup_economy-3mmI_ .keyFeature-2oVPM:nth-child(n + 3) {
	background: var(--scat-light-blue);
}

.fareGroup_economy-3mmI_ .keyFeature-2oVPM:nth-child(-n + 2) {
	background: rgba(93, 194, 239, 0.8);
}

.fareGroup_business-3ZZXy .keyFeature-2oVPM:nth-child(n + 3) {
	background: var(--scat-blue);
}

.fareGroup_business-3ZZXy .keyFeature-2oVPM:nth-child(-n + 2) {
	background: rgba(20, 63, 135, 0.8);
}

.option-3R9Pv {
	padding: 9px 0;
}

.option-3R9Pv:not(:last-of-type) {
	border-bottom: 1px solid #e0e0e0;
}

button.fareGroup__button-1Sc7F:disabled {
	color: #ffffff;

	box-shadow: none;
	background: var(--gray30) !important;
}

.fareGroup_business-3ZZXy {
	background: var(--white);
}

.badge__wrapper-3zsIc {
	position: absolute;
	top: -18px;
	right: 10px;
}

.badge__wrapper-3zsIc svg {
	border-radius: 50%;
}

.badge__innner-1f5UZ {
	z-index: 1;
}

.badge-uqczv {
	position: static;
	z-index: 1;

	box-shadow: none;
	transform: rotate(0);
	background-color: var(--scat-green);
}

.badge__shadow-dqdUJ {
	position: absolute;
	top: 17px;

	width: 100%;
	height: 3px;

	transform: rotate(2.6deg);
	opacity: 0.2;
	background: #292929;
}

.fareGroup_business-3ZZXy button.fareGroup__button-1Sc7F {
	background-color: var(--scat-green);
	box-shadow: none;
}

.fareGroup_business-3ZZXy button.fareGroup__button-1Sc7F:hover {
	background-color: var(--scat-green);
	box-shadow: none;
}

div.tooltip-3MOrp {
	border-radius: 0;
}

.price-2gjU7 {
	font-weight: 600;
}

.fareGroup__white-2vu1o .fareGroup__optionIcon-1IYoZ,
.fareGroup__white-2vu1o .fareGroup__optionTitle-2DrQc,
.fareGroup__white-2vu1o .fareGroup__optionValue-36SJ4 {
	color: var(--base2);
}

.fareGroupGrid__option-z7hXv {
	color: var(--white);

	background: var(--base2);
}

.fareGroupGrid__option-z7hXv .fareGroupGrid__icon-3Oxwk {
	color: var(--white);
}

@media screen and (max-width: 600px) {
	.fareGroup-3bl-l {
		margin-top: 16px;
		margin-bottom: 26px;

		width: 320px;

		box-shadow: 3px 6px 34px rgba(0, 0, 0, 0.12);
	}

	.fareGroup__white-2vu1o .fareGroup__optionTitle-2DrQc {
		white-space: inherit;
	}

	.price-2gjU7 {
		font-weight: 600;
	}
}
