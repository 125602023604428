

.terminal-2JIt2 {
	display: none;
}

.weather__temperature-1Iggs,
.point-3kaI1 > span:first-child {
	font-size: 18px;
}

.point-3kaI1 {
	font-weight: 600;
	font-size: 18px;
}

.airportName-2hb_c {
	font-size: 15px;
	font-weight: 500;
	color: #333333;
}

.duration-3aZYf {
	font-size: 15px;
}

.points-3BCFV {
	width: 25%;
}

.weather-2e92V {
	position: relative;

	width: 11%;
	margin-left: 0;
}

.weather-2e92V:after {
	position: absolute;
	right: 23px;

	width: 1px;
	height: 100%;

	background: #e0e0e0;
	content: '';
}

.amenities_available-1PNmJ {
	color: var(--scat-text-blue);
}

.amenities_unavailable-2LjKl {
	color: #bdbdbd;
}

a.segment__link-2pFgN {
	font-size: 15px;
	font-weight: 500;
	color: #2f80ed;
	text-decoration: underline;
}

.segment__plane-2HKnd {
	margin-right: 30px;
	width: 250px;
	min-height: 80px;
}

.segment__airline-3BzjZ {
	margin-bottom: 10px;

	color: var(--scat-dark-gray);
	font-weight: 600;
	font-size: 18px;
}

.flight-17x4K {
	justify-content: center;
}
