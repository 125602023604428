

.wrapper_business-F1uh9:not(.active-vw4Ig) .flightPrice__prefix-19TEr {
	color: var(--scat-blue);
}

.wrapper_business-F1uh9.active-vw4Ig .flightPrice__prefix-19TEr {
	color: var(--scat-blue);
}

button.flightPrice__wrapper-aBech {
	border-left: 0;
}

.price-1Zlea {
	right: 28px;

	font-size: 27px;
}
