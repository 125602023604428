.container-2nC3u {
	position: relative;

	border-left: 1px solid var(--line-separator);
}

.label-3JbS4 {
	position: absolute;
	left: 14px;

	color: var(--text-light);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;

	text-transform: lowercase;

	pointer-events: none;
}

@media screen and (max-width: 600px) {
	.container-2nC3u {
		margin: 5px 0 10px;

		border-top: 1px solid var(--line-separator);
		border-left: none;
	}

	.label-3JbS4 {
		left: 0;
		top: 5px;
	}
}
