.startSearch-Ufhdb {
	flex-basis: 58px;
}

.startSearch-Ufhdb button {
	width: 58px;
	min-width: 58px;
	height: 58px;

	padding: 0;

	border-radius: 50%;

	background: var(--brand-1-0);
}

.startSearch-Ufhdb button svg {
	display: none;
}

.startSearch-Ufhdb button:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 40%;
	height: 40%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNC4xMTMzIDIxLjcyTDE5LjU4IDE3LjJDMjEuMDQyNiAxNS4zMzY2IDIxLjgzNjMgMTMuMDM1NSAyMS44MzMzIDEwLjY2NjdDMjEuODMzMyA0Ljc3NTYzIDE3LjA1NzcgMCAxMS4xNjY3IDBDNS4yNzU2MyAwIDAuNSA0Ljc3NTYzIDAuNSAxMC42NjY3QzAuNSAxNi41NTc3IDUuMjc1NjMgMjEuMzMzMyAxMS4xNjY3IDIxLjMzMzNDMTMuNTM1NSAyMS4zMzYzIDE1LjgzNjYgMjAuNTQyNiAxNy43IDE5LjA4TDIyLjIyIDIzLjYxMzNDMjIuNzM4NiAyNC4xMzYyIDIzLjU4MjggMjQuMTM5NiAyNC4xMDU2IDIzLjYyMTFDMjQuMTA4MiAyMy42MTg1IDI0LjExMDggMjMuNjE1OSAyNC4xMTMzIDIzLjYxMzNWMjMuNjEzM0MyNC42MzYyIDIzLjA5NDggMjQuNjM5NiAyMi4yNTA2IDI0LjEyMTEgMjEuNzI3N0MyNC4xMTg1IDIxLjcyNTEgMjQuMTE1OSAyMS43MjI2IDI0LjExMzMgMjEuNzJMMjQuMTEzMyAyMS43MlpNMy4xNjY2NyAxMC42NjY3VjEwLjY2NjdDMy4xNjY2NyA2LjI0ODM5IDYuNzQ4MzkgMi42NjY2NyAxMS4xNjY3IDIuNjY2NjdDMTUuNTg0OSAyLjY2NjY3IDE5LjE2NjcgNi4yNDgzOSAxOS4xNjY3IDEwLjY2NjdDMTkuMTY2NyAxNS4wODQ5IDE1LjU4NDkgMTguNjY2NyAxMS4xNjY3IDE4LjY2NjdDNi43NDgzOSAxOC42NjY3IDMuMTY2NjcgMTUuMDg0OSAzLjE2NjY3IDEwLjY2NjdMMy4xNjY2NyAxMC42NjY3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
	transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
	.startSearch-Ufhdb {
		flex: 0;
	}
}

@media screen and (max-width: 600px) {
	.startSearch-Ufhdb {
		position: relative;
		left: auto;
		right: auto;
		bottom: auto;

		margin-top: 0;
	}
}
