.items-dzUrL {
	width: 90%;
	padding: 0 12px 20px;
}

.passenger-3yQyb {
	position: static;
}

.baggage__passenger-2Ww83 {
	border-radius: 0;
}

@media screen and (max-width: 600px) {
	.items-dzUrL {
		width: 100%;
		padding-top: 0;
		margin-bottom: -20px;
	}
}
