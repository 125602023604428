.close-3sn6M {
	color: var(--white);
}

@media screen and (max-width: 600px) {
	.header-3rsII {
		font-size: 20px;
		color: #333333;
		font-weight: 600;
	}
}
