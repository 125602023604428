.container-20qEe.identity-36BIG {
	grid-template-columns: repeat(1, auto);
}

.label_middleName-37wIJ,
.identity-36BIG .field_middleName-2XHgC {
	display: none;
}

@media (max-width: 1024px) {
	.container-20qEe.identity-36BIG {
		grid-template-rows: 1fr 1fr [row-content-end] 1fr;
		height: -moz-fit-content;
		height: fit-content;
	}

	.container-20qEe.documents-1w1pN {
		grid-auto-rows: initial;
	}
}
