

.flight-hIzlK {
	max-width: var(--l-container);

	border-radius: 0;
}

.header__cell-4kpIr:nth-child(3) {
	background: var(--scat-blue);
}
