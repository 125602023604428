div.wrapper-2W91E {
	display: flex;
	align-items: center;

	line-height: inherit;
}

.value-3vuQU {
	color: var(--brand-1-0);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.2;
}

.code-1rRev {
	display: none;
}

@media screen and (max-width: 600px) {
	.wrapper_alternative-1PfCo .value-3vuQU {
		font-size: 18px;
		font-weight: 700;

		opacity: 1;
	}
}
