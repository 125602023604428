.flightCard__airline-ZeuJF {
	color: var(--gray50);
}

.flightCard__airlineLogo-J2RmN {
	display: none;
}

.flightCard__airlineNumber-3IOA0 {
	display: block;
}

.flightCard__flightBlock-3JTxk:first-child {
	display: none;
}

.flightCard__flightTitle-2TQZ- {
	display: none;
}

.flightCard__flightValue-aRJ1s {
	color: var(--text);
	font-size: 14px;
	line-height: 1.3;
}

.flightCard__flightValue-aRJ1s > svg {
	display: block;

	color: var(--gray30);
}

.flightCard__footer-6-1FQ {
	flex-direction: row-reverse;
	justify-content: center;
}

.flightCard__aircraftNameActive-1ak84 {
	color: var(--gray50);
	font-weight: 400;
}

.flightCard__info-3NUBr {
	width: 18px;
	height: 18px;
}

.aircraft_return-14rj8 .aircraft__image-3rkkL {
	transform: none;
}

@media screen and (max-width: 600px) {
	.flightCard__aircraftNameActive-1ak84 {
		margin-left: 0;
	}
}
