

.day__price-1jbbE {
	color: var(--base2);
}

.day-2pr2n.day_best-22WmQ .day__price-1jbbE {
	color: var(--success-20);
}

.selected-ivMav.day-2pr2n {
	border-bottom-color: var(--base2);
}
