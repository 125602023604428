.header__day-tPXz- {
	width: 48px;
	height: 48px;
	margin: 0 1px;
}

.months__wrapper-l_RYl {
	padding: 20px 26px;
}

.day__day-3_NOk > div {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0;
}

.day__day-3_NOk.day__selected-f7uq4 {
	padding-top: 0;
}

.day__day-3_NOk.day__selected-f7uq4:not(.day__start-35HQq, .day__end-1xp_D) {
	border-radius: 0;

	background: var(--datepicker-day-period-background);
}

.day__day-3_NOk.day__inPeriod-3HgF8 .day__inside-1mKyQ {
	color: var(--gray20);
}

.day__day-3_NOk.day__inPeriod-3HgF8.highlightDate-2aTC4 .day__inside-1mKyQ,
.day__day-3_NOk.day__selected-f7uq4.highlightDate-2aTC4:not(.day__start-35HQq, .day__end-1xp_D) .day__inside-1mKyQ {
	color: var(--scat-blue);
}

.day__day-3_NOk.day__inPeriod-3HgF8 {
	padding-top: 0;

	background: var(--datepicker-day-period-background);
}

.day__today-3G_W6:not(.day__selected-f7uq4) {
	border-radius: 0;
}

.day__start-35HQq {
	border-radius: 10px 0 0 10px;
}

.day__end-1xp_D {
	border-radius: 0 10px 10px 0;
}

.day__start-35HQq,
.day__end-1xp_D {
	padding-top: 0;

	color: var(--white);

	background: var(--scat-blue);
}

@media screen and (max-width: 600px) {
	.months__wrapper-l_RYl {
		padding-top: 80px;
	}
}
