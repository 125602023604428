.checkout_paidOrderWrapper-2RXNI {
	max-width: var(--l-container);
}

.toggleFlights-Rx1Hq {
	background: var(--scat-light-blue);
}

.selectedFlight__container-1hCYD {
	max-width: var(--l-container);
}

a.baggageService__rulesLink-2JJuZ {
	color: var(--white);
}

.header-1FfWb {
	margin: 0;
	margin-bottom: 28px;
	padding: 28px calc((100vw - var(--l-container)) / 2);

	background: var(--white);
}

.summary__wrp-2BF2i {
	max-width: 100%;
	margin-top: 0;
}

.summary-8a7hs {
	max-width: var(--l-container);
	margin: 0 auto;
}

.container__seats-2-BLJ {
	background: var(--checkout-background);
}

.passengers__header--o_qj {
	padding: 0 calc((100vw - var(--l-container)) / 2);
	height: 82px;
}

.passengers__header--o_qj:after {
	display: none;
}

.passengers__form-21hSe {
	max-width: var(--l-container);
}

.checkoutServices-2mVaU {
	max-width: var(--l-container);
}

.detailsModal__header-30QDi {
	background: var(--scat-blue);
}

.container-2Zezf {
	padding-bottom: 120px;
}

@media (max-width: 1024px) {
	.passengers__header--o_qj {
		padding-left: 20px;
	}

	.selectedFlight__container-1hCYD,
	.checkoutServices-2mVaU {
		padding: 0 20px;
	}
}

@media (max-width: 768px) {
	.container-2Zezf {
		padding-bottom: 140px;
	}
}

@media screen and (max-width: 600px) {
	.container-2Zezf {
		padding-bottom: 80px;
	}

	.passengers__form-21hSe form > label {
		padding-left: 16px;
	}

	.checkoutServices-2mVaU {
		justify-content: center;
	}

	span.label-2zDHT {
		padding: 10px 0;
	}
}

.checkout_terms-3U3gw {
	color: #5698f0;
}

.checkout_messages-2gp-E {
	max-width: var(--l-container);
}
