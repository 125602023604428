

.inner-1GIZ2 {
	max-width: var(--l-container);
}

button.toolbarButton-2sSZ_ {
	background: var(--scat-green);
}

button.toolbarButton-2sSZ_:hover {
	background: var(--scat-green);
}

div.toolbarButton-2sSZ_,
button.disabled-2ZOvA {
	background: var(--scat-green) !important;
}

.actions__left-2H84e {
	flex: 2;
}

.actions-3yTt- {
	width: auto;
}
