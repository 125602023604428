.searchForm-QESAH {
	background: var(--white);
}

.searchForm__row-Yh1hY {
	max-width: var(--l-container);
}

.cell_date-3iQOe > div:first-child > div,
.cell_date-3iQOe > div:first-child > div:not(.datepicker__dates_focused-bVgPi):hover,
.cell_date-3iQOe > div:first-child > div.datepicker__dates_focused-bVgPi {
	color: var(--scat-darken-gray);
}

.segment__input-H_rN8,
.currency-I0h-h .currency_selector-3YqtR,
.currency-I0h-h .currency_selector-3YqtR:hover,
.datepicker__dates-3wTra,
.passengers__selector-3FN3b,
.passengers__selector-3FN3b:not(:focus):not(.passengers__selector_focused-3eHr5):hover,
.passengers__selector_focused-3eHr5,
.segment__input-H_rN8.segment__input_focused-1BDS4:focus,
.datepicker__dates-3wTra.datepicker__dates_focused-bVgPi,
.segment__input-H_rN8:not(:focus):not(.segment__input_focused-1BDS4):not(.currency_selector-3YqtR):hover,
.datepicker__dates-3wTra:not(.datepicker__dates_focused-bVgPi):hover {
	color: var(--scat-darken-gray);

	background-color: #f2f2f2;
}

.currency-I0h-h .currency_selector-3YqtR:hover {
	background-color: #f2f2f2 !important;
}

.currency_arrow--xoBX {
	color: var(--scat-gray);
}

.datepicker__dates_back-peOjH:before {
	border-left-color: #cdcdcd;

	opacity: 1;
}

.passengers__arrowIcon-dOrF4,
.passengers__arrowIcon_flipped-3JGkP {
	color: var(--scat-gray);
}

.value__value-1ftrP,
.value__code-VjuRk {
	color: var(--scat-darken-gray);
}

.datepicker__dates_to-RbIcP:before {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiAySDE1VjBIMTNWMkg1VjBIM1YySDJDMC44OSAyIDAuMDEgMi45IDAuMDEgNEwwIDE4QzAgMTkuMSAwLjg5IDIwIDIgMjBIMTZDMTcuMSAyMCAxOCAxOS4xIDE4IDE4VjRDMTggMi45IDE3LjEgMiAxNiAyWk00IDlWMTFINlY5SDRaTTEwIDlIOFYxMUgxMFY5Wk0xNCA5VjExSDEyVjlIMTRaTTIgMThIMTZWN0gyVjE4WiIgZmlsbD0iIzlBOUE5QSIvPgo8L3N2Zz4K);
}

.segment__switcher-eZYYg {
	color: var(--scat-darken-gray);
}

.multicity__caption-OzBIp {
	color: #797876;
}

.segment__input-H_rN8:-ms-input-placeholder {
	color: var(--scat-darken-gray);

	opacity: 1;
}

.segment__input-H_rN8::placeholder {
	color: var(--scat-darken-gray);

	opacity: 1;
}

.segment__input-H_rN8.input_withoutPlaceholder-2xR6O:-ms-input-placeholder {
	opacity: 0;
}

.segment__input-H_rN8.input_withoutPlaceholder-2xR6O::placeholder {
	opacity: 0;
}

.segment__input-H_rN8:focus:-ms-input-placeholder {
	color: var(--scat-darken-gray);

	opacity: 1;
}

.segment__input-H_rN8:focus::placeholder {
	color: var(--scat-darken-gray);

	opacity: 1;
}

.datepicker__placeholder-3n-zE {
	color: var(--scat-darken-gray);
}

.datepicker__returnDelete-13lR4 {
	color: var(--scat-darken-gray);
}

.location__input-3DXlt:focus {
	background-color: #f2f2f2;
}

.location__input-3DXlt:-ms-input-placeholder, .location__input-3DXlt:-ms-input-placeholder:focus {
	background-color: #f2f2f2;
}

.location__input-3DXlt::placeholder,
.location__input-3DXlt::placeholder:focus {
	background-color: #f2f2f2;
}

.dropdown-1FgRk {
	color: var(--dark-gray);

	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

.currency-I0h-h {
	margin: 0;

	border-left: 1px solid var(--line-separator);
}

@media screen and (max-width: 600px) {
	.searchForm-QESAH {
		background: var(--scat-light-blue);
	}

	.segment__switcher-eZYYg.location__switcher_alternative-26F3p {
		color: var(--white);

		background: var(--scat-light-blue);
	}

	.multicity__caption-OzBIp {
		border-bottom: 0;

		color: var(--white);
		font-size: 16px;

		opacity: 1;
	}

	.segment__input-H_rN8.input_withoutPlaceholder-2xR6O,
	.segment__input-H_rN8.input_withoutPlaceholder-2xR6O:hover {
		color: transparent !important;
	}

	.autocomplete_alternative-Yak0j .location__input-3DXlt {
		color: var(--scat-darken-gray);
	}

	.currency-I0h-h {
		margin-bottom: 54px;
	}

	.segment__input-H_rN8,
	.cell_date-3iQOe > div > div,
	.passengers__selector-3FN3b {
		border-radius: 0;
	}

	.dropdown-1FgRk {
		box-shadow: none;
	}
}
