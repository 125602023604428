.list-1oeq6 {
	width: 49%;
}

li.listItem-3FXTO {
	margin-right: 0;
	width: 100%;
}

li.listItem-3FXTO:nth-of-type(n + 9) {
	margin-left: 20px;
}

.groups_item-3mUL- {
	flex: auto;
}

.title-B3G9T {
	flex: 0 0 25%;
}

@media screen and (max-width: 600px) {
	.list-1oeq6 {
		width: 100%;
	}

	li.listItem-3FXTO {
		margin: 0 5px;
	}

	li.listItem-3FXTO:nth-of-type(n + 9) {
		margin-left: 0;
	}
}

@media (max-width: 1024px) {
	.header-tiofy {
		display: block;
	}

	.title-B3G9T {
		margin-bottom: 20px;
	}

	.groups_item-3mUL- {
		flex: none;
	}
}
