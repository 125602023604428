.passengers-21KOD button {
	background-color: transparent;
}

.selector-Mlj0- {
	height: 60px;
	min-height: 60px;

	margin: 0;
	padding: 26px 14px 0;
}

.selector-Mlj0-,
.selector-Mlj0-:not(:focus):not(.selector_focused-3nqh3):hover,
.selector_focused-3nqh3 {
	background-color: transparent;
}

.title-2Wi74 {
	color: var(--brand-1-0);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.2;
}

@media screen and (max-width: 600px) {
	.passengers-21KOD {
		margin-top: 0;
		margin-bottom: 0;
	}

	.alternative-G_8zq .title-2Wi74 {
		font-size: 18px;
		font-weight: 700;

		text-align: left;
	}

	.alternative-G_8zq .selector-Mlj0- {
		height: 54px;
		min-height: 54px;

		padding: 26px 14px 0 0;
	}
}
