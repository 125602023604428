.field-2Xs_w {
	align-items: flex-end;
}

.name-3QHo8 {
	padding-bottom: 5px;
	width: 200px;
}

.input-2jccR {
	max-width: 50%;
}

@media screen and (max-width: 600px) {
	.field-2Xs_w {
		padding: 0;
	}

	.field-2Xs_w:not(:first-child) {
		margin-top: 25px;
	}

	.input-2jccR {
		max-width: unset;
	}
}
