div.paperRoot-2AQ8E {
	border-radius: 20px;
}

.header-3AQpE {
	height: 80px;
	padding: 0 !important;
}

.heading-3Avsn {
	padding: 0 32px;

	font-size: 22px !important;
	line-height: 28.6px !important;
	font-weight: 700 !important;
	color: var(--text-base) !important;
}

.tabsWrp-2NvCu {
	padding: 0 32px;
}

button.tab-2M1O4 {
	color: var(--text-light);
	font-size: 14px;
	font-family: inherit;
}

.tab_active-2ytOh {
	color: var(--brand1) !important;
}

.fareConditionsSegmentHeading__inner-1s7nZ {
	display: none;
}

.fareConditionsSegmentHeading-3dqyA {
	padding: 0 32px;
}

.fareConditionsSegmentFareCode-2k0ih {
	padding: 0 32px 10px;

	color: var(--text-base);
	font-weight: 400;
	font-size: 14px;
}

.fareConditionsContent-1lqSG {
	padding: 0 32px;

	color: var(--text-base);
	font-weight: 400;
	font-size: 14px;
	overflow-y: auto;
}

.fareConditionsContent-1lqSG pre {
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 600px) {
	div.paperRoot-2AQ8E {
		height: 100%;

		border-radius: 0;
	}

	.tabsWrp-2NvCu {
		padding: 0 12px;
	}

	.segmentTabs-fw-SA {
		padding: 0;
	}

	.fareConditionsSegmentHeading-3dqyA {
		padding: 0 12px;
	}

	.fareConditionsSegmentFareCode-2k0ih {
		padding: 0 12px 10px;
	}

	.fareConditionsContent-1lqSG {
		padding: 0 12px;
		max-height: calc(100vh - 145px);
	}
}
