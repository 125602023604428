

.service_hasProducts-2sWZG svg path {
	fill: #727272;
}

.service_canAdd-TVOhY:not(.service_hasProducts-2sWZG) {
	background: var(--scat-blue);
}

.service__totalPrice-3WDHT {
	color: var(--scat-blue);
}
