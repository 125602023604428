

.stepbar-3n9ft {
	padding-bottom: 0;

	background-color: var(--mobileStepbar-background);
}

.header-XqiCM {
	height: 48px;
}
