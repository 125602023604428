

.container-33pPb {
	max-width: var(--l-container);
}

.price-3AHwt {
	color: var(--base2);
}

.price__money-2s5zc {
	color: var(--base2);
}

button.button-1zyvg {
	border-radius: 100px;

	color: var(--base2);

	background: var(--base80);
}

.single__time-3GhTG,
.single__timeInRoute-34lUL {
	color: var(--base2);
}

.single__date-20Wlx {
	color: var(--gray50);
}

.route__time-N2bbG span {
	color: #2f80ed;
}

.single__aircraft-3LEiP {
	margin: 0;

	color: #2f80ed;
}

.segment__planeInfo-2aCYq {
	margin-top: 4px;
	justify-content: center;
}

.segment__aircraft-2GJPg {
	margin-right: 0;
	padding-right: 0;

	border-right: none;

	text-align: center;
}

.pointDate__container--KYpX,
.point_arrival-3-z3g .pointDate__container--KYpX {
	align-items: center;
}

.point__airport-3l_nB,
.point_arrival-3-z3g .point__airport-3l_nB {
	text-align: center;
}
