.wrapper-1V3HM {
	height: 100%;

	background: var(--checkout-background);
}

.promo-1WC6J {
	background-color: var(--common-blue);
}

.promo__image-UosII:after {
	display: none;
}

.promo__content-2OrWQ {
	background: #ffffff;
}

@media screen and (max-width: 600px) {
	.wrapper-1V3HM {
		width: 100%;
	}

	.inner-svjzQ {
		top: 80px;
	}
}
