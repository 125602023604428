@media screen and (max-width: 600px) {
	.date-jrWjS {
		font-size: 27px;
		font-weight: 600;
	}

	.timeEnRoute__text-2ZabX {
		font-size: 15px;
		font-weight: 600;
	}

	.short-1OCW_ {
		font-size: 13px;
	}

	.filled-38ZKP .date-jrWjS,
	.filled-38ZKP .timeEnRoute__text-2ZabX,
	.filled-38ZKP .short-1OCW_ {
		color: #333333;
	}

	.filled-38ZKP .timeEnRoute-EX0D7:before,
	.filled-38ZKP .timeEnRoute-EX0D7:after {
		background: #bababa;
	}

	.filled-38ZKP div.date-jrWjS:after {
		border-color: #bababa;
	}

	.airport__city-cOC4Y {
		font-size: 15px;
		line-height: 22px;
	}

	.details-3N7nf {
		font-size: 14px;
	}

	.details__short-1OqXQ {
		padding-top: 18px;
		margin-bottom: -5px;
	}

	.airport__name-2QYui {
		line-height: 19px;
	}

	.details__arrow-1Uher {
		border-radius: 50%;

		background: var(--scat-blue) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMi42ODM2IDEuNDFMMTEuMTk5MSAwTDYuMzY2NzYgNC41OEwxLjUzNDM5IDBMMC4wNDk5MzMgMS40MUw2LjM2Njc2IDcuNDFMMTIuNjgzNiAxLjQxWiIgZmlsbD0iI0ZGRkZGRiIvPgo8L3N2Zz4K) 6px 9px no-repeat;
	}

	.flight_withAircraft-1ha8i .segments-28qrv {
		padding-bottom: 20px;
	}

	.flight_withAircraft-1ha8i svg.recommended {
		bottom: -214px;
	}
}
