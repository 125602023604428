.dates-39cr7 {
	height: 60px;
	min-height: 60px;

	border-right: 1px solid var(--line-separator);

	line-height: inherit;

	background-color: transparent;
}

.dates__to-3fZ-P {
	padding-left: 24px;
}

.dates__back-3CAgC {
	padding-left: 4px;
	padding-right: 24px;
}

.dates_focused-3Uufl .dates__to-3fZ-P:after {
	display: none;
}

.dates__to-3fZ-P,
.dates__back-3CAgC {
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;

	color: var(--brand-1-0);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.2;
}

.dates__to-3fZ-P:before,
.dates__back-3CAgC:before {
	display: none;
}

.dates__to-3fZ-P > span,
.dates__back-3CAgC > span,
.label-2iwbR,
.placeholder-cZzDV {
	margin-bottom: 8px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 15px;
	line-height: 1.5;

	text-transform: lowercase;
}

.label-2iwbR.label_hidden-pwai8 {
	display: block;
}

.dow-2eODU {
	opacity: 1;
}

@media (max-width: 1024px) {
	.dates-39cr7 {
		min-height: 54px;

		border-right: none;
	}

	.dates__to-3fZ-P {
		padding-left: 0;
	}

	.dates__back-3CAgC {
		padding-left: 12px;
	}
}

@media screen and (max-width: 600px) {
	.alternative-2IjsN .dates__to-3fZ-P {
		align-items: flex-start;
		flex-direction: column-reverse;
	}

	.alternative-2IjsN .dates__back-3CAgC {
		border-left: 1px solid var(--line-separator);
	}

	.alternative-2IjsN .dates__to-3fZ-P,
	.alternative-2IjsN .dates__back-3CAgC {
		font-size: 18px;
		font-weight: 700;
		line-height: 1.2;
		text-align: left;
	}

	.alternative-2IjsN .dow-2eODU {
		display: inline;
	}
}
