.day__progress-1umuL {
	opacity: 0.6;
}

@media (min-width: 1025px) {
	.day-3L2Ja.outDate-1vvwg .day__progress-1umuL,
	.day-3L2Ja.backDate-sqXix .day__progress-1umuL {
		background: var(--base);
	}
}

@media (max-width: 1024px) {
	.dv__priceGraphContainer-1ZAxZ {
		padding-top: 40px;
	}

	.dv__priceGraphCalendar-2vgtv {
		padding-left: 70px;
	}
}
