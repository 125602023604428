.sortings__wrp-PUuQ8 {
	max-width: var(--l-container);
}

.selectedFares__flight-1_NZ6 {
	max-width: var(--l-container);
}

.fareLock__root-b769Y {
	max-width: var(--l-container);
}

.results__outer-2ohne {
	display: none;
}

.outer__bottom-2mEmp {
	max-width: var(--l-container);
}

.calendar__header-25iFB {
	padding: 0;
	max-width: var(--l-container);
}

.calendar__content-2jOMO {
	max-width: var(--l-container);
}

.title-3F8dv {
	font-size: 19px;
}

.title__text-2D-8w {
	font-size: 24px;
}

.legNumber-U_obr {
	background: var(--base2);
}

.title__text-2D-8w svg {
	color: var(--gray30);
}

button.selectFare-2gEu_ {
	background: var(--scat-green) !important;
}

button.selectFare-2gEu_:hover {
	opacity: 0.8;
}

button.calendar__tab-1Ddev {
	font-size: 16px;
}

button.diagramButton-8Kkih {
	display: none;

	color: var(--scat-text-blue);
}

button.diagramButton-8Kkih g {
	opacity: 1;
}

.disclaimer__wrapper-2oWVY {
	font-size: 15px;
}

div.toolbar__nextButton-1JV0P > div {
	background-image: url(data:image/svg+xml;base64,PHN2ZyAgd2lkdGg9IjE3NHB4IiBoZWlnaHQ9IjE3NHB4IiB2aWV3Qm94PSIwIDAgNjYgNjYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8Y2lyY2xlIGNsYXNzPSJwYXRoIiBmaWxsPSJ0cmFuc3BhcmVudCIgc3Ryb2tlLXdpZHRoPSIyIiBjeD0iMzMiIGN5PSIzMyIgcj0iMzAiIHN0cm9rZT0idXJsKCNncmFkaWVudCkiLz4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiPgogICAgICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICAgICAgPHN0b3Agb2Zmc2V0PSI2NSUiIHN0b3AtY29sb3I9IndoaXRlIiBzdG9wLW9wYWNpdHk9Ii41Ii8+CiAgICAgICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSJ3aGl0ZSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50Pgo8L3N2Zz4K);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 75%;
}

.dummyCalendar__wrapper-1JCxR {
	padding: 0;
}

.dummyCalendar__item-2s7KW {
	min-height: 81px;
	justify-content: center;
}

.dummyCalendar__head-16rue {
	display: none;
}

.dummyCalendar__middle-3sKxb {
	flex-grow: 0;
	padding: 0 0 0 12px;
	margin: 14px 0 0 0;
	justify-content: flex-start;
	align-items: center;

	border-bottom: none;
}

.dummyCalendar__footer-1f5cn {
	padding-left: 12px;

	text-align: left;
}

.dummyCalendar__footer-1f5cn span {
	width: 40px;
}

.messages-30RlQ {
	margin: 0 auto 20px;
	padding: 0;
}

.messages-30RlQ,
.disclaimer__wrapper-2oWVY {
	max-width: var(--l-container);
}

.plane_return-1sUVt img {
	left: 22px;

	transform: none;
}

@media screen and (max-width: 600px) {
	.inner-1D6ql {
		border-radius: 0;
	}

	.flightsList__leg-3hTci {
		text-align: center;
		font-size: 20px;
		color: #333333;
	}

	.priceGraph__otherDates-3M5qy {
		color: #828282;
	}

	.disclaimer__wrapper-2oWVY {
		font-size: 13px;
	}

	.disclaimer__wrapper-2oWVY:only-child {
		border-radius: 0;
	}

	.flight-B62DR {
		border-radius: 0;

		box-shadow: 3px 6px 34px rgba(0, 0, 0, 0.12);
	}

	.flight-B62DR:not(:last-of-type) {
		margin-bottom: 12px;
	}

	button.flight__price-39-Ey {
		border-radius: 0;
	}

	.fareConditions__content-1WfXi > div {
		max-height: 100%;
	}
}
