.datepicker__disclaimer-29W6j {
	margin-bottom: 64px;
}

@media (min-width: 1025px) {
	.popup-3hNCz {
		width: calc(100% + 92px);
		margin-left: -46px;
	}
}

@media (max-width: 1024px) {
	div.footer__buttons-1eWOf {
		position: fixed;
		bottom: 32px;

		width: 450px;
	}
}

@media screen and (max-width: 600px) {
	div.footer__buttons-1eWOf {
		bottom: 0;

		width: 100%;
	}
}
