

button.button_done-3e7T6 {
	background: var(--scat-green) !important;
}

button.button_done-3e7T6:hover {
	background: var(--scat-green) !important;
}

button.button_clear-397s3 {
	border: 1px solid #f2f2f2;

	color: #828282;
}

.dialog__content-2WIjM {
	background: var(--checkout-background);
}
