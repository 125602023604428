.wrapper-x3PiD {
	top: 2px;

	margin-top: 0;
	margin-right: 0;
}

@media screen and (max-width: 600px) {
	.wrapper-x3PiD {
		padding: 0;
		width: 100%;
	}
}

.wrapper-x3PiD.active-3P1cj:before {
	opacity: 1;
}

.wrapper-x3PiD:not(.active-3P1cj):after {
	background: var(--gray-line);
}

.label-1o8tM {
	grid-gap: 4px;
	gap: 4px;
}

.label-1o8tM,
.wrapper-x3PiD:not(.active-3P1cj) .label-1o8tM {
	color: var(--gray50);
	font-size: 16px;
	font-weight: 500;

	opacity: 1;
}

div.tooltip-2z4w2 {
	max-width: 240px;
}

.tooltip__title-AVRFW {
	text-align: center;
}

.tooltip__titleText-16w6J > span {
	color: var(--warning-icon);
}

@media screen and (max-width: 600px) {
	.label-1o8tM,
	.wrapper-x3PiD:not(.active-3P1cj) .label-1o8tM {
		color: var(--white);
	}
}
