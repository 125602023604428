.month-2Zjmm {
	padding-right: 15px;
}

.day-1OTls {
	width: 48px;
	height: 48px;

	margin: 1px;
	padding: 0;

	color: var(--scat-blue);
	font-weight: 500;

	font-size: 15px;
	line-height: 18px;

	background: #f2f2f2;
}

.day-1OTls > div {
	width: 100%;
	height: 100%;

	padding-top: 6px;

	color: inherit;

	line-height: inherit;
}

.day_inPeriod-kRt4w.day_highlighted-1pMaa div:not(.day_hasPrice-1F4HV) {
	color: #bdbdbd;

	pointer-events: none;
}

.weekday-1DqIf {
	width: 50px;
}

.day_notAvailable-2B0k0 {
	opacity: 1;
}

div.next-1NkB- {
	right: 20px;
}

div.prev-3f0Th {
	left: 20px;
}

.day-1OTls .day_hasPrice-1F4HV {
	padding-top: 6px;

	color: var(--scat-blue);

	background: var(--datepicker-day-period-background);
}

.day_inPeriod-kRt4w {
	border-top: 1px solid var(--scat-blue);
	border-bottom: 1px solid var(--scat-blue);
}

.day_inPeriod-kRt4w .day_hasPrice-1F4HV {
	color: var(--scat-blue);

	background: transparent;
}

.day_selected-2Ni6-.day_startPeriod-1whp2.day_endPeriod-27Uu4 {
	background: var(--scat-blue) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjY2IDEyLjY2NjdMNCAxMEw2LjY2IDcuMzMzMzVWOS4zMzMzNUgxMS4zMzMzVjEwLjY2NjdINi42NlYxMi42NjY3Wk00IDQuNjY2NjdIOC42NzMzM1YyLjY2NjY3TDExLjMzMzMgNS4zMzMzNEw4LjY3MzMzIDguMDAwMDFWNi4wMDAwMUg0TDQgNC42NjY2N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) 32px 2px no-repeat;
}

.day__price-2lV8F {
	color: inherit;
	font-size: 10px;
}

.day_hasPrice-1F4HV:hover {
	color: var(--white) !important;

	background: var(--scat-blue) !important;
}

.day_selected-2Ni6- .day__inside-21Zov {
	font-weight: 500;
}

.day-1OTls.day_selected-2Ni6- {
	padding-top: 8px;

	border-radius: 10px;

	background: var(--scat-blue);
}

.day_withPrice-2XGwK:not(.day_endHover_withPrice-36QnX):not(.day_startHover_withPrice-2Ul4s):not(.day_selected-2Ni6-):hover {
	border: 1px solid var(--scat-blue) !important;
}

.day_selected-2Ni6-.day_startPeriod-1whp2 {
	border-radius: 10px 0 0 10px;
}

.day_selected-2Ni6-.day_endPeriod-27Uu4 {
	border-radius: 0 10px 10px 0;
}

.day_selected-2Ni6- .day_hasPrice-1F4HV {
	width: auto;
	height: auto;

	background: var(--scat-blue);
}

button.datepicker__tab_selected-2k9J5 {
	color: #2f80ed;
}

button.datepicker__tab-2uJlL {
	height: 70px;
}

button.footer__done-1KOR7 {
	background: var(--scat-light-blue);
}

button.footer__done-1KOR7:hover {
	background: var(--scat-blue);
}

span.tab__indicator-rQmUD {
	background: #2f80ed;
}

.day_endPeriod-27Uu4 .day__inside-21Zov {
	border-radius: 0 50% 50% 0;
}

.day_startPeriod-1whp2 .day__inside-21Zov {
	border-radius: 50% 0 0 50%;
}

.day_startPeriod-1whp2.day_endPeriod-27Uu4 .day__inside-21Zov {
	border-radius: 50%;
}

.monthList__currentMonth-1ktAb {
	padding-right: 0;

	pointer-events: none;
}

.monthList__currentMonth-1ktAb:after {
	display: none;
}

.content-16tOc {
	margin-bottom: 18px;
}

.disclaimer-6AT6H {
	display: none;
}

@media (max-width: 1024px) {
	.month-2Zjmm {
		padding-right: 0;
	}

	.month-2Zjmm:not(:last-child) {
		padding-bottom: 40px;
	}
}

@media screen and (max-width: 600px) {
	.content-16tOc {
		margin-top: 20px;
	}

	.alternative-3tpvR.oneDate-3vlyi > div > span:nth-child(2) {
		color: transparent;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCTxwYXRoIG9wYWNpdHk9IjAuNSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOSA0SDE4VjJIMTZWNEg4VjJINlY0SDVDMy44OSA0IDMuMDEgNC45IDMuMDEgNkwzIDIwQzMgMjEuMSAzLjg5IDIyIDUgMjJIMTlDMjAuMSAyMiAyMSAyMS4xIDIxIDIwVjZDMjEgNC45IDIwLjEgNCAxOSA0Wk03IDExVjEzSDlWMTFIN1pNMTMgMTFIMTFWMTNIMTNWMTFaTTE3IDExVjEzSDE1VjExSDE3Wk01IDIwSDE5VjlINVYyMFoiIGZpbGw9IiM0ZjRmNGYiLz4KPC9zdmc+Cg==) center no-repeat;
	}

	button.datepicker__tab_selected-2k9J5 {
		color: var(--scat-gray);
	}

	button.datepicker__tab-2uJlL svg {
		margin-right: 10px;
	}

	.disclaimer-6AT6H {
		display: none;
	}

	.graphHeader__dates-3zCC-,
	.graphHeader__price-1LGmT {
		color: var(--white);
	}

	.graphHeader__dates-3zCC- {
		background: var(--scat-blue);
	}

	.monthsWrapper_wrapper-1ntn5 {
		margin-top: 0;
	}
}
