

.wrapper-1UILc {
	border-radius: 0;

	box-shadow: 3px 6px 34px rgba(0, 0, 0, 0.12);
}

.body-2AEXJ {
	border-bottom: 1px dashed #bab8b7;
}

.header-1kJlX,
.footer-21maq {
	color: #333333;
}

.date-3-UlI,
.short-TQK3G,
.timeEnRoute__text-n05q1 {
	color: #333333;
}

.footer-21maq {
	color: #292929;
	font-size: 14px;
	font-weight: 600;
}

.mobileSelectedFare-1UIIl {
	border-radius: 0;
}

.price-3cIRa {
	color: var(--scat-blue);
}
