.location-2Dy3S {
	position: relative;

	min-height: 60px;
}

input.input-1bXUS {
	position: relative;

	height: 60px;

	padding: 26px 16px 0 0;

	border: none;

	color: transparent;
	font-weight: 500;
	font-size: 15px;
	line-height: 1.2;

	background: transparent;
}

input.input-1bXUS:focus {
	z-index: 10;

	color: var(--text-base);
}

.label-12eV9 {
	position: absolute;
	top: 0;
	left: 0;

	z-index: 1;

	color: var(--text-light);
	font-weight: 400;
	font-size: 15px;
	line-height: 1.5;

	text-transform: lowercase;

	pointer-events: none;
}

div.value-2i-gF {
	padding: 26px 16px 0 0;
}

.arrival-3ubvM .label-12eV9 {
	left: 26px;
}

.arrival-3ubvM input.input-1bXUS,
.arrival-3ubvM div.value-2i-gF {
	padding-left: 26px;
}

.switcher-2acne {
	position: absolute;
	top: 50%;
	right: 0;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	border: 1px solid var(--brand-1-0);
	border-radius: 50%;

	color: var(--brand-1-0);

	transform: translate(50%, -50%);

	cursor: pointer;
	background: var(--white);
}

@media screen and (max-width: 600px) {
	input.input-1bXUS {
		height: 54px;
	}

	.label-12eV9 {
		top: 2px;
	}

	div.value-2i-gF {
		align-items: flex-start;

		padding: 26px 16px 6px 0;
	}

	.arrival-3ubvM .label-12eV9 {
		left: 0;
	}

	.arrival-3ubvM input.input-1bXUS,
	.arrival-3ubvM div.value-2i-gF {
		padding-left: 0;
	}

	.switcher-2acne {
		top: auto;
		right: 20px;
		bottom: 0;

		padding: 0;

		transform: translateY(50%) rotate(90deg);
	}
}
