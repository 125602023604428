

.mobileScreen__header-1gC6s {
	background: linear-gradient(180deg, var(--scat-light-blue) 0%, var(--scat-blue) 100%);
}

.rules-1-Rl- {
	color: #ffffff;
}

.wrapper-2Ucjy {
	background: #f0f0f0;
}

.insurances-116mM {
	padding: 2px;
}

.controls-3PmZH {
	background: #f0f0f0;
}

.documents-103pV {
	margin-top: 20px;
	padding: 10px 20px 34px;

	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
	background: #ffffff;
}

button.dialog__button_done-UWTWK {
	color: #ffffff;
	font-weight: 600;

	background: var(--scat-green);
}

button.dialog__button_done-UWTWK:hover {
	background: var(--scat-green);
}

button.dialog__button_clear-2Dd0k {
	border: 1px solid #e0e0e0;

	color: #828282;
	font-weight: 600;

	background: #ffffff;
}
