button.increase-2Abm8:not(:disabled),
button.decrease-2jHCS:not(:disabled) {
	color: #2f80ed;

	background: rgba(93, 194, 239, 0.2);
}

button.increase-2Abm8:not(:disabled):hover,
button.decrease-2jHCS:not(:disabled):hover {
	background: rgba(93, 194, 239, 0.4);
}

.info-3k4s9 {
	flex-flow: column wrap;
	flex-direction: column;
	margin-right: 0;
}

.type-2GZCi {
	margin-right: 5px;
}

.passenger-M8RzZ {
	max-width: var(--l-container);
}

.counter-3BKKi {
	color: #333333;
}

.passenger__headerServices-1nqUc {
	display: none;
}

@media screen and (max-width: 600px) {
	.info-3k4s9 {
		flex-basis: 55%;
		align-items: center;
	}

	.description-ehXBd {
		display: block;
	}
}
