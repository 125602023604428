.flight-2KUdD {
	max-width: var(--l-container);

	border-radius: 0;
}

.inner-Bl1BS {
	justify-content: flex-start;
}

.col-9gOTH,
.col__head-KpFbX {
	font-size: 18px;
}

.col_sub-PVHb9 {
	font-size: 14px;
}

.col_duration-EhR0M,
.col_time-1YKsg {
	align-items: center;
}

.fareGroup_stub-i7cW- {
	display: none;
	max-width: 287px;
}

.col_time-1YKsg.col_singleFare-3K2p4,
.col_duration-EhR0M.col_singleFare-3K2p4 {
	flex-basis: 225px !important;
}

.date-2L6P2.date_singleFare-ORhjv {
	font-size: 18px;
}

.col_duration-EhR0M {
	min-width: 212px;
	margin: 0 15px;
	padding: 2px 15px;

	border: 1px solid #e0e0e0;
	border-top: 0;
	border-bottom: 0;
}

.col_duration-EhR0M .col_sub-PVHb9 {
	margin-left: 15px !important;
	padding-left: 15px;

	border-left: 1px solid #e0e0e0;

	line-height: 28px;
}

.col_time-1YKsg .col_sub-PVHb9 {
	margin-left: 25px !important;
	padding-left: 15px;

	border-left: 1px solid #e0e0e0;

	line-height: 28px;
}

.col_details-3Xt0I {
	flex: 1;
	align-items: center;
}

.col_details-3Xt0I .col_sub-PVHb9 {
	margin-left: 15px !important;
	padding-left: 15px;

	border-left: 1px solid #e0e0e0;

	line-height: 28px;
}

div.desktopFlightPopup_paper-1u1xq {
	border-radius: 0;
}

.desktopFlight__headerTitle-15CbI {
	color: var(--text);
}

.desktopFlight__close-1o8sQ {
	color: var(--base2);
}

.desktopFlight__closeIcon-w-xu4 {
	background-color: var(--base2);
}

button.desktopFlight__button-tGcBg {
	border-radius: 100px;

	background-color: var(--white);
}

button.desktopFlight__button-tGcBg.desktopFlight__buttonActive-1QAAb {
	background-color: var(--base2);
}

a.desktopFlight__compareFaresLink-4oyzf {
	color: var(--gray50);
}

.desktopFlight__compareFaresLinkIcon-38mgS {
	color: var(--gray30);
}

.desktopFlight__locationTerminal-aJXxE {
	color: var(--base2);
}

.desktopFlight__locationTime-3EUuX {
	color: var(--text);
}

.desktopFlight__locationTimeIata-2iYcP {
	color: var(--gray50);
}

.slider__wrp-3UxDV.slider__wrp_singleItem-1DfxX .fareGroup__slide-1UFJp {
	max-width: 37.5%;
}

@media (min-width: 1025px) {
	.modalContent_paper-29vbV .slick-slider {
		padding-left: 0;
	}
}

@media (max-width: 1024px) {
	.slider__wrp-3UxDV.slider__wrp_singleItem-1DfxX .fareGroup__slide-1UFJp {
		max-width: 50%;
	}
}

@media screen and (max-width: 600px) {
	.desktopFlight__closeIcon-w-xu4 {
		background-color: #f6f6f6;
	}

	.desktopFlight__compareFaresLink-4oyzf {
		display: none;
	}

	button.desktopFlight__scrollUp-3vwhR {
		width: 100%;
	}

	.slider__wrp-3UxDV.slider__wrp_singleItem-1DfxX .fareGroup__slide-1UFJp {
		max-width: 100%;
	}
}
