

.promoCode__container-3Auql,
a.promoCode__clear-1LqNY {
	color: var(--scat-blue);
}

.promoCode__icon-2Ux2N,
.promoCode__iconNotApplied-1UwdC {
	margin-right: 4px;
}

button.promoCodeForm__button-216Ca {
	border-radius: 50%;
}
