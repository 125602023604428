.cell-1PZIX:not(:last-child) {
	margin-right: 0;

	border-right: 1px solid var(--line-separator);
}

.cell_date-144rD {
	flex-basis: 286px;
}

.cell_date-144rD > div:first-child > div,
.cell_date-144rD > div:first-child > div:not(.dates_focused-3CMzh):hover,
.cell_date-144rD > div:first-child > div.dates_focused-3CMzh {
	background-color: transparent;
}

@media (max-width: 1024px) {
	.cell-1PZIX:not(:last-child) {
		padding-right: 0;
	}

	.cell_location-1cHks.cell_location_arrival-rhxvk {
		border-right: none;
	}

	.cell_date-144rD {
		flex-basis: 50%;

		height: 60px;

		margin-top: 28px;
		padding-right: 0;

		border-right: 1px solid var(--line-separator);
	}
}

@media screen and (max-width: 600px) {
	.cell-1PZIX:not(:last-child) {
		border-right: none;
		border-bottom: 1px solid var(--line-separator);
	}

	.cell_date-144rD {
		margin: 6px 0;

		border-right: none;
	}

	.alternative-2FNzB .cell_date-144rD {
		height: 54px;
	}

	.alternative-2FNzB .cell_location-1cHks {
		flex-basis: 100%;
	}

	.alternative-2FNzB .cell_location-1cHks:first-of-type {
		margin-bottom: 0;
		padding-right: 0;
	}

	.alternative-2FNzB div.cell_location-1cHks:nth-child(2) {
		margin-bottom: 0;
		padding-left: 0;
	}
}
