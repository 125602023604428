

.popupHeader-36iY8 {
	border-radius: 0;

	color: #ffffff;
}

div.paper-271O_ {
	border-radius: 0;
}

.dialog__header-3OLXC,
.header-3S9u- {
	color: #ffffff;

	background: var(--scat-blue);
}
