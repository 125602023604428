

.label-raeUT {
	opacity: 1;
}

.active-290_B.wrapper-3Ynlc:before {
	background: var(--white);
}

.active-290_B.wrapper-3Ynlc:after {
	background: var(--scat-green);
}
