button.closeButton-6LKDj,
button.closeButton-6LKDj:hover,
button.closeButton-6LKDj:focus,
button.closeButton-6LKDj:active {
	position: absolute;
	top: 50%;
	right: 40px;
	left: auto;

	border-radius: 100px;

	color: var(--gray70);

	transform: translateY(-50%);

	background: var(--gray05);
}
