

.orderPassengers_inner-11-kz {
	z-index: 1;

	height: auto;
}

.orderPreview_overlay-3v8Pw {
	z-index: 0;
}

.commonItem_root-1ji_r {
	flex: 1;
}

.commonItem_payload-1HbEA {
	font-weight: 500;
}

.orderStatus_root-3Swms.orderStatus_typeReview-2Wny6.orderStatus_confirmed-3Qebu {
	color: var(--success-20);
}

.orderPassengers_root-1v5l7 .commonItem_payload-1HbEA {
	color: var(--gray80);
}

.orderBooking_downloadWrapper-25OZz > button {
	background-color: var(--base);
}

.orderBooking_downloadWrapper-25OZz > button > svg {
	display: none;
}

.orderBooking_downloadWrapper-25OZz > button,
.orderBooking_downloadWrapper-25OZz > button:hover {
	border: none;

	color: var(--white);
}

.orderBooking_downloadWrapper-25OZz > button:hover {
	background-color: var(--base15);
}

.orderCard_container-1TsG9.orderCard_typeReview-1b4Tz button {
	height: 36px;

	border-radius: 40px;

	line-height: 36px;
}

.orderTickets_link-aHZk0 {
	color: var(--base);
}

.orderStatus_transactions-20_Du {
	font-weight: 400;
}

.paymentStatus_root-2ZPzj.paymentStatus_account-3fDQr {
	color: var(--white);

	background: var(--error);
}

.paymentStatus_root-2ZPzj.paymentStatus_account-3fDQr .paymentStatus_icon-z-Dt3 {
	color: var(--white);
}

.passengersModal_passengersWrapper-28WQT {
	padding-top: 70px;
}
