.container-2QXrV {
	position: absolute;
	top: -12px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	width: 100%;

	padding: 28px 28px;

	border-top: 12px solid var(--brand-1-0);

	box-shadow: 0 0 1px rgba(48, 49, 51, 0.05), 0 16px 24px rgba(48, 49, 51, 0.1);
}

.container-2QXrV input {
	outline: none;
}

div.dates_focused-1RIdx,
div.dates_focused-1RIdx:hover {
	background-color: #ffffff !important;
}

div.cell_date-nJ8g7 > div:first-child > div,
div.cell_date-nJ8g7 > div:first-child > div:not(.dates_focused-1RIdx):hover,
div.cell_date-nJ8g7 > div:first-child > div.dates_focused-1RIdx {
	background-color: #ffffff;
}

div.currency-2BAUI {
	border-left: none;
}

.searchButton-OFi5q button {
	width: 58px !important;
	min-width: auto !important;

	border-radius: 50% !important;
}

.row-2oIrg:not(:last-of-type) {
	padding: 0 0 20px 0;

	border-bottom: 1px solid var(--line-separator);
}

@media (max-width: 1024px) {
	.container-2QXrV {
		position: relative;
		top: 0;

		padding: 16px 30px;
		display: flex;
	}

	.row-2oIrg:not(:last-of-type) {
		padding: 0 0 20px 0;

		border-bottom: 1px solid var(--line-separator);
	}
}

@media screen and (max-width: 600px) {
	.container-2QXrV {
		padding: 16px 20px;

		background: var(--white);
	}

	.searchButton-OFi5q button {
		border-radius: 50% !important;
	}
}
