

div.dialog__header-KKltq {
	border: none;
}

div.dialog__header-KKltq [class^='Dialog'] {
	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;

	background: white;
}

div.dialog__header-KKltq button[class*='closeButton'] {
	right: 12px;

	width: 32px;
	min-width: 32px;
	height: 32px;
	padding: 0;

	border-radius: 50%;

	color: #808080;
	font-size: 0;

	background: #f6f6f6;
}

div.dialog__header-KKltq button[class*='closeButton'] span:before {
	display: block;
	width: 20px;
	height: 20px;
	margin: 6px;

	background: var(--text-light);
	content: '';
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjgzMjcgNS4zNDE2NkwxNC42NTc3IDQuMTY2NjZMOS45OTkzNSA4LjgyNUw1LjM0MTAyIDQuMTY2NjZMNC4xNjYwMiA1LjM0MTY2TDguODI0MzUgMTBMNC4xNjYwMiAxNC42NTgzTDUuMzQxMDIgMTUuODMzM0w5Ljk5OTM1IDExLjE3NUwxNC42NTc3IDE1LjgzMzNMMTUuODMyNyAxNC42NTgzTDExLjE3NDMgMTBMMTUuODMyNyA1LjM0MTY2WiIgZmlsbD0iIzgwODA4MCIvPgo8L3N2Zz4K);
	        mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjgzMjcgNS4zNDE2NkwxNC42NTc3IDQuMTY2NjZMOS45OTkzNSA4LjgyNUw1LjM0MTAyIDQuMTY2NjZMNC4xNjYwMiA1LjM0MTY2TDguODI0MzUgMTBMNC4xNjYwMiAxNC42NTgzTDUuMzQxMDIgMTUuODMzM0w5Ljk5OTM1IDExLjE3NUwxNC42NTc3IDE1LjgzMzNMMTUuODMyNyAxNC42NTgzTDExLjE3NDMgMTBMMTUuODMyNyA1LjM0MTY2WiIgZmlsbD0iIzgwODA4MCIvPgo8L3N2Zz4K);
	-webkit-mask-position: center;
	        mask-position: center;
	-webkit-mask-repeat: no-repeat;
	        mask-repeat: no-repeat;
	-webkit-mask-size: 20px 20px;
	        mask-size: 20px 20px;
}
