

.selected-2d9Bj {
	position: relative;

	text-transform: uppercase;
}

.selected-2d9Bj:after {
	content: '';

	position: absolute;
	right: -22px;
	top: -3px;

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjAiIGhlaWdodD0iMjEiPgo8cGF0aCBkPSJNNS44MzMzNCA4LjgzMzMzTDEwIDEzTDE0LjE2NjcgOC44MzMzM0g1LjgzMzM0WiIgZmlsbD0iIzQxNDE0MSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazApIj4KPHJlY3QgeT0iMC41IiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IiNCREJEQkQiLz4KPC9nPgo8L3N2Zz4K) center no-repeat;
}

.lang_opened-1OrtV .selected-2d9Bj:after {
	transform: rotate(180deg);
}

.dropdown-3HXyP:after {
	filter: drop-shadow(0 -3px 5px rgba(0, 0, 0, 0.15));
}

.icon-3bVJP {
	display: none;
}
