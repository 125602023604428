

.promoCode__container-3Auql,
a.promoCode__clear-1LqNY {
	color: var(--scat-blue);
}

.promoCode__icon-2Ux2N,
.promoCode__iconNotApplied-1UwdC {
	margin-right: 4px;
}

button.promoCodeForm__button-216Ca {
	height: 45px;
	margin-right: 0;

	border-radius: 50px;
}

div.promoCodeForm__codeInput-6U1x3:after {
	content: ' ';

	z-index: 2;
	position: absolute;

	right: 10px;
	top: 0;
	bottom: 0;

	width: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDJMMTEuMDU5IDIuMDAxNzNMMTEuMTE2NiAyLjAwNjczTDExLjE3NjQgMi4wMTU2TDExLjIyOTMgMi4wMjY0MUwxMS4zMzczIDIuMDU4M0wxMS40MDM5IDIuMDg0OTJMMTEuNTM2MSAyLjE1NTY5TDExLjYyNTQgMi4yMTk2OUwxMS43MDcxIDIuMjkyODlMMjAuNzQ1NCAxMS4zMzMzQzIxLjU1MzcgMTIuMjM3MSAyMS41OTIyIDEzLjU4MjEgMjAuODI3OCAxNC41NjI4TDIwLjcwNzEgMTQuNzA3MUwxNC42NjY3IDIwLjc0NTRDMTMuNzYyOSAyMS41NTM3IDEyLjQxNzkgMjEuNTkyMiAxMS40MzcyIDIwLjgyNzhMMTEuMjkyOSAyMC43MDcxTDIuMjkyODkgMTEuNzA3MUMyLjEzNjYxIDExLjU1MDggMi4wMzc0IDExLjM0ODEgMi4wMDg2NyAxMS4xMzE0TDIgMTFWN0MyIDQuMjM4NTggNC4yMzg1OCAyIDcgMlpNMTAuNTg1IDRIN0M1LjQwMjMyIDQgNC4wOTYzNCA1LjI0ODkyIDQuMDA1MDkgNi44MjM3M0w0IDdWMTAuNTg1TDEyLjY2NjcgMTkuMjU0NkMxMi44MzI3IDE5LjQwMzIgMTMuMDczMyAxOS40MjE4IDEzLjIzMDkgMTkuMzM2TDEzLjI5MjkgMTkuMjkyOUwxOS4yNTQ2IDEzLjMzMzNDMTkuNDAzMiAxMy4xNjczIDE5LjQyMTggMTIuOTI2NyAxOS4zMzYgMTIuNzY5MUwxOS4yOTI5IDEyLjcwNzFMMTAuNTg1IDRaTTkgNkM3LjM0MzE1IDYgNiA3LjM0MzE1IDYgOUM2IDEwLjY1NjkgNy4zNDMxNSAxMiA5IDEyQzEwLjY1NjkgMTIgMTIgMTAuNjU2OSAxMiA5QzEyIDcuMzQzMTUgMTAuNjU2OSA2IDkgNlpNOSA4QzkuNTUyMjggOCAxMCA4LjQ0NzcyIDEwIDlDMTAgOS41NTIyOCA5LjU1MjI4IDEwIDkgMTBDOC40NDc3MiAxMCA4IDkuNTUyMjggOCA5QzggOC40NDc3MiA4LjQ0NzcyIDggOSA4WiIgZmlsbD0iIzFBNTdCQiIvPgo8L3N2Zz4K) no-repeat center center;
}

div.promoCodeForm__statusMessage-1D-4T {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}
