div.footer__scrollBody-260rW .closeIcon-3I8vx {
	top: 10px;
	right: 10px;
}

@media screen and (max-width: 600px) {
	.footer__slideBottom-1OTRs {
		padding-top: 48px;
	}

	div.footer__scrollBody-260rW .social-1lWrf {
		margin-top: 0;
	}
}
