

.container-292aB {
	display: flex;
	flex-direction: column;
}

.wrapper-3fn9X {
	position: relative;

	width: 100%;
	max-width: var(--l-container);

	margin: 0 auto;
}
