

.nav_next-27Ys7,
.nav_prev-18CoI {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.nav_next-27Ys7 {
	right: 25px;
}

.nav_prev-18CoI {
	left: 25px;
}
