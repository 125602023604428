

.fareType_economy-1z7MW.active-3d-vc {
	color: var(--white);

	background: var(--scat-light-blue);
}

.fareType_economy-1z7MW.active-3d-vc:after {
	border-color: var(--scat-light-blue);
}

.fareType_business-GeWJ1.active-3d-vc {
	color: var(--white);

	background: var(--scat-blue);
}

.fareType_business-GeWJ1.active-3d-vc:after {
	border-color: var(--scat-blue);
}
